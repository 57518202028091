import { type AlternativeLanguage } from '@replay/types/AlternativeLanguage';
import { concatWithReplayBaseUrl } from './concatWithReplayBaseUrl';

export type Language = Pick<AlternativeLanguage, 'code' | 'url'>;

export const getAlternateTags = ({ languages }: { languages: Language[] | null }) => {
    if (!languages?.length) {
        return null;
    }
    return languages.map(({ code, url }) => {
        const ensuredUrl = url.startsWith('http') ? url : concatWithReplayBaseUrl(url);
        return <link key={`alternate-${code}`} rel="alternate" href={ensuredUrl} hrefLang={code} />;
    });
};
