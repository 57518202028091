import { appleStoreAppId, facebookAppId, facebookPageIds, googlePlayAppId, twitterProfiles } from './constants';
import { type Locale } from '@replay/i18n/types/locale';
import { type Meta } from '@replay/types/Meta';

export const getSocialTags = ({
    ensuredUrl,
    language,
    title,
    description,
    meta,
}: {
    ensuredUrl: string;
    language: Locale;
    title?: string;
    description?: string;
    meta: Meta;
}) => {
    const socialTags = [
        <meta key="og:image" property="og:image" content={meta.og.image.url} />,
        <meta key="og:image:height" property="og:image:height" content={meta.og.image.height.toString()} />,
        <meta key="og:image:width" property="og:image:width" content={meta.og.image.width.toString()} />,

        <meta key="twitter:card" name="twitter:card" content="summary_large_image" />,
        <meta key="twitter:image" name="twitter:image" content={meta.twitter.image.url} />,
        <meta key="twitter:site" name="twitter:site" content={twitterProfiles[language]} />,
        <meta key="twitter:app:name:iphone" name="twitter:app:name:iphone" content="ARTE" />,
        <meta key="twitter:app:id:iphone" name="twitter:app:id:iphone" content={appleStoreAppId} />,
        <meta key="twitter:app:name:ipad" name="twitter:app:name:ipad" content="ARTE" />,
        <meta key="twitter:app:id:ipad" name="twitter:app:id:ipad" content={appleStoreAppId} />,
        <meta key="twitter:app:name:googleplay" name="twitter:app:name:googleplay" content="ARTE" />,
        <meta key="twitter:app:id:googleplay" name="twitter:app:id:googleplay" content={googlePlayAppId} />,
        <meta key="fb:app_id" property="fb:app_id" content={facebookAppId} />,
        ...facebookPageIds.map((pageId) => <meta key={pageId} property="fb:pages" content={pageId} />),

        <meta key="al:ios:app_store_id" property="al:ios:app_store_id" content={appleStoreAppId} />,
        <meta key="al:ios:app_name" property="al:ios:app_name" content="ARTE" />,
        <meta key="al:android:package" property="al:android:package" content={googlePlayAppId} />,
        <meta key="al:android:app_name" property="al:android:app_name" content="ARTE" />,
        <meta key="al:web:url" property="al:web:url" content={ensuredUrl} />,
        <meta key="al:web:should_fallback" property="al:web:should_fallback" content="true" />,

        <meta
            key="apple-itunes-app"
            name="apple-itunes-app"
            content={`app-id=${appleStoreAppId}, app-argument=${ensuredUrl}`}
        />,
    ];

    if (title) {
        socialTags.push(<meta key="og:title" property="og:title" content={title} />);
    }
    if (description) {
        socialTags.push(<meta key="og:description" property="og:description" content={description} />);
    }

    return socialTags;
};
