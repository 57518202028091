import { type Locale } from '@replay/i18n/types/locale';
import { ogLocale } from './constants';

export const getBaseTags = ({ language }: { language?: Locale }) => {
    const baseTags = [
        <meta key="utf-8" charSet="utf-8" />,
        <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1.0" />,
        <meta key="copyright" name="copyright" content="ARTE" />,
        <meta key="og:type" property="og:type" content="website" />,
        <meta key="og:site_name" property="og:site_name" content="ARTE" />,
        <meta key="apple-mobile-web-app-title" name="apple-mobile-web-app-title" content="ARTE" />,
        <meta key="application-name" name="application-name" content="ARTE" />,
        <meta key="msapplication-TileColor" name="msapplication-TileColor" content="#FD4600" />,
        <meta key="theme-color" name="theme-color" content="#FD4600" />,
    ];
    if (language && ogLocale[language]) {
        baseTags.push(<meta key="og:locale" property="og:locale" content={ogLocale[language]} />);
    }
    return baseTags;
};
