export const ogLocale = {
    fr: 'fr_fr',
    de: 'de_de',
    en: 'en_gb',
    es: 'es_es',
    pl: 'pl_pl',
    it: 'it_it',
};

export const appleStoreAppId = '405028510';
export const googlePlayAppId = 'tv.arte.plus7';
export const facebookAppId = '1040824609280221';

export const defaultImageStorage = '/img/';
export const defaultShareImage = '/img/SHARE_DEFAULT.jpg';
export const defaultWidth = 1200;
export const defaultHeight = 630;

export const appleTouchIconSizes = ['57x57', '60x60', '72x72', '76x76', '114x114', '120x120', '152x152', '180x180'];
export const faviconSizes = ['16x16', '32x32', '96x96', '194x194'];

export const twitterProfiles = {
    fr: '@ARTEfr',
    de: '@ARTEde',
    en: '@ARTEen',
    es: '@ARTEesp',
    pl: '@ARTEpl',
    it: '@ARTEita',
};

export const facebookPageIds = [
    '134545446597376',
    '1529846017308575',
    '58523373944',
    '86384367059',
    '100979826222',
    '111947632154155',
    '847686631913128',
    '63220817254',
    '238910993868',
    '114532941895479',
    '178787450795',
    '134148050102438',
    '110237302415157',
    '269393220166',
    '1319236851430224',
    '1431579507148714',
    '133764746751356',
    '118010568269537',
    '173758322989273',
    '656089814494677',
    '21250474792',
    '321411107954015',
    '40297827989',
    '311595228873763',
    '117553961604283',
    '208731645292',
    '259460764173369',
    '218821614864696',
    '478627765544122',
    '207683959418318',
    '127294640785',
    '147625758710434',
    '244980932233809',
    '10150155272765160',
    '1180635995384762',
    '1870250979934789',
    '158499951404564',
    '481802645522215',
];
