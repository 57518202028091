import React, { useEffect, useRef } from 'react';
import Page404 from '../pages/404';
import Page500 from '../pages/500';
import { HttpError, isHttpError, type ReplayError } from '@replay/types/Type_Error';
import { type Paths } from '@replay/types/Utils';
import { fetchJson } from '@replay/fetch';

type LogForObject = {
    [K in keyof GlobalError]?: {
        [P in GlobalError[K]['err']['type']]?: P extends 'Http'
            ? { [C in HttpError['codeDefinition']]?: boolean }
            : boolean;
    };
};

type LogForStrings = Paths<LogForObject> | 'All';

const sanitizeError = (error: ReplayError): Record<string, unknown> | undefined => {
    if (error.type === 'Fetch') {
        const type = `${error.type}.${error.err.type}`;

        return { ...error.err, type };
    }
    if (error.type === 'HomePage') {
        return error.err;
    }
    if (error.type === 'ProgramPage') {
        return error.err;
    }
    if (error.type === 'Unknown') {
        return error.err;
    }
};

async function callLogger(error: ReplayError) {
    const sanitizedError = sanitizeError(error);
    if (sanitizedError) {
        fetchJson('/api/logger/', {
            requestInit: { method: 'POST', body: JSON.stringify(sanitizedError) },
        });
    }
}
/**
 * An object that specifies which log messages should be included or excluded.
 *
 */
export type LogFor = {
    /*
     * An array of LogForStrings that specifies which log messages should be included. If not specified, all log messages are included.
     */
    include?: LogForStrings[];
    /*
     * An array of LogForStrings that specifies which log messages should be excluded. If not specified, no log messages are excluded.
     */
    exclude?: LogForStrings[];
};

type Props = {
    /**
     * An object that represents an error that occurred in the application.
     */
    error: ReplayError;
    /**
     * An object that specifies which log messages should be included or excluded.
     */
    logFor?: LogFor;
    /**
     * A function that is called with the error object when the error should be logged.
     * The default implementation sends an HTTP POST request to the /api/logger/ endpoint
     * with the error object as the request body.
     * This is mainly here for test purpose, but you can overide it if you want.
     */
    logger?: (error: ReplayError) => Promise<void>;
};

const getPathError = (error: ReplayError) => {
    const paths: LogForStrings[] = [error.type];
    let path: LogForStrings = error.type;
    path = `${path}.${error.err.type}` as LogForStrings;
    paths.push(path);
    if (error.type === 'Fetch' && error.err.type === 'Http') {
        path = `${path}.${error.err.codeDefinition}` as LogForStrings;
        paths.push(path);
    }
    return paths;
};

type EnsuredLogFor = {
    include: LogForStrings[];
    exclude: LogForStrings[];
};

const shouldLog = (error: ReplayError, logFor: EnsuredLogFor) => {
    const pathError = getPathError(error);

    // The reducer function takes an accumulator object and a value and returns
    // an updated accumulator object.
    const reducer =
        (scope: 'exclude' | 'include') => (acc: { depth: number; value: boolean }, v: LogForStrings, i: number) => {
            // If the value is included in the given array, the depth property is set
            // to the index of the value (i) and the value property is set to true.
            // Otherwise, the accumulator object is returned unchanged.
            if (logFor[scope].includes(v)) {
                return { depth: i, value: true };
            }
            return acc;
        };

    // The isErrorExcluded and isErrorIncluded variables are created by calling
    // the reducer function with the pathError array and the logFor.exclude and
    // logFor.include arrays, respectively.
    const isErrorExcluded = pathError.reduce(reducer('exclude'), { depth: -1, value: logFor.exclude.includes('All') });

    const isErrorIncluded = pathError.reduce(reducer('include'), { depth: -1, value: logFor.include.includes('All') });

    if (isErrorExcluded.value && isErrorIncluded.value) {
        return isErrorIncluded.depth >= isErrorExcluded.depth;
    }

    return isErrorIncluded.value;
};

const defaultInclude: LogForStrings[] = ['All'];
const defaultExclude: LogForStrings[] = ['Fetch.Http.NotFound'];
const defaultLogFor: LogFor = { include: defaultInclude, exclude: defaultExclude };

/**
 * The Error component is a higher-order component that takes an error prop and an
 * optional logFor prop, which specifies which log messages should be included or
 * excluded. The error prop is an object that represents an error that occurred in
 * the application. The logFor prop is an object that has two optional properties:
 * include, which is an array of strings that specifies which log messages should be
 * included, and exclude, which is an array of strings that specifies which log
 * messages should be excluded.
 *
 * @example
 * ```typescript
 * import { Error } from './Error';
 *
 * const MyComponent = () => {
 *   const [error, setError] = useState<Error | null>(null);
 *
 *   const handleClick = () => {
 *     try {
 *       doSomething();
 *     } catch (e) {
 *       setError(e);
 *     }
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={handleClick}>Do something</button>
 *       {error && <Error error={error} />}
 *     </div>
 *   );
 * };
 * ```
 */
export const Error = ({ error, logFor = defaultLogFor, logger = callLogger }: Props) => {
    const isMounted = useRef(true);

    useEffect(() => {
        const ensuredLogFor: EnsuredLogFor = {
            include: logFor?.include || ['All'],
            exclude: logFor?.exclude || ['All'],
        };
        if (isMounted.current && shouldLog(error, ensuredLogFor)) {
            logger(error);
        }

        return () => {
            isMounted.current = false;
        };
    }, [error, logFor, logger]);

    if (isHttpError(error.err)) {
        if (error.err.codeDefinition === 'NotFound') {
            return <Page404 />;
        }
    }

    return <Page500 error={error} />;
};
